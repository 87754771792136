<template>
  <div class="modern-color-theme font-poppins flex flex-col gap-2" data-component-name="VUpload">
    <VSLabel v-if="props.label" :for="id">{{ props.label }}</VSLabel>
    <div
      class="min-h-[150px] flex-1 relative border border-dashed rounded-lg bg-neutral-100"
      :class="{
        'pointer-events-none':
          status.type === 'pending' ||
          status.type === 'success',
        'border-neutral-300': !props.disabled,
        'border-neutral-100': props.disabled
      }"
    >
      <input
        :id="id"
        type="file"
        aria-label="file"
        class="absolute inset-0 !opacity-0 w-full"
        :class="{
          'cursor-not-allowed': props.disabled,
          'cursor-pointer': !props.disabled
        }"
        :multiple="multiple"
        :accept="accepts"
        :disabled="disabled"
        @change="inputChange"
      >
      <div
        class="pointer-events-none flex flex-col items-center gap-2 justify-center absolute inset-0"
        :class="{
          'text-neutral-300': props.disabled,
          'text-neutral-600': !props.disabled
        }"
      >
        <VIcon v-if="status.type === 'error'" size="3xl" color="red" name="Outline/error" />
        <VIcon v-else-if="status.type === 'success'" size="3xl" name="Outline/check" />
        <VIcon v-else-if="status.type === 'pending'" size="3xl" class="loading" name="Outline/progress-activity" />
        <VIcon v-else size="3xl" :name="props.icon" />
        <span v-text="status.text" />
      </div>
    </div>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
  </div>
</template>
<script lang="ts" setup generic="TResponseData">
import type { PathHelper } from '@js-from-routes/client';
import { ref, computed, watch } from 'vue'
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import VIcon from './../labels/VIcon.vue'
import { useElementId } from '../../utils/utils';
import type { Icon } from '@icons/index';
import { useLocalize } from '@component-utils/localization';

const localize = useLocalize('avv_upload')

defineOptions({
  name: 'VUpload'
})

type Status = {
  type:
    | 'pending'
    | 'success'
    | 'error'
    | 'idle'
  text: string
}

const props = withDefaults(
  defineProps<{
    endpoint: PathHelper
    endpointParams?: Parameters<PathHelper['path']>[0]
    id?: string
    icon?: Icon
    accept?: string | string[]
    multiple?: boolean
    /**
     * - `true`
     *     - Success message: Clears after 5 seconds
     *     - Re-uploads: Yes
     * - `'once'`
     *     - Success message: Permanent
     *     - Re-uploads: No
     * - `'captive'`
     *     - Success message: Permanent
     *     - Re-uploads: Yes
     */
    repeat?: true | 'once' | 'captive'
    text?: string
    disabled?: boolean
    label?: string
    description?: string
  }>(),
  {
    id: undefined,
    endpointParams: undefined,
    icon: 'Outline/cloud-upload',
    accept: '*',
    multiple: false,
    repeat: true,
    text: useLocalize('avv_upload')('defaults.hint'),
    disabled: false,
    label: undefined,
    description: undefined
  }
)

const emit = defineEmits<{
  upload: [data: TResponseData, files: File[]]
}>()

const id = useElementId(props.id)

const accepts = computed(() =>
  typeof props.accept === 'string' ? props.accept : props.accept.join(',')
)

const status = ref<Status>({ type: 'idle', text: props.text })

const validateFile = (file: File): string | false => {
  if (file.size >= 256e6) {
    return localize('errors.file_too_big')
  }

  return false
}

const timeout = ref<NodeJS.Timeout>()

const inputChange = async (event: Event) => {
  event.preventDefault()
  event.stopPropagation()

  status.value = { type: 'pending', text: localize('status.pending') }

  const target = event.target as HTMLInputElement
  const files = target.files as FileList

  // Validate file size
  for (const file of files) {
    const error = validateFile(file)
    if (error) {
      status.value = {
        type: 'error',
        text: error
      }

      return
    }
  }

  // Construct payload
  const payload = { file: props.multiple ? files : files[0] }

  // Send payload
  await props.endpoint<TResponseData>({
    params: props.endpointParams,
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then((data) => {
    emit('upload', data, Array.from(files))

    status.value = {
      type: 'success',
      text: localize(props.repeat === 'captive' ? 'status.success_with_captive_repeat' : 'status.success'),
    }
  }).catch((e) => {
    if (typeof e.response === 'object') {
      const { data } = e.response

      if (typeof data === 'string') {
        // Something failed and wasnt responded to as json 
        status.value = {
          type: 'error',
          text: localize('errors.code', { code: 500 })
        }
      } else {
        status.value = {
          type: 'error',
          text: data.error as string
        }
      }
    } else {
      // Possible javascript error
      status.value = {
        type: 'error',
        text: localize('errors.code', { code: 500 })
      }
    }
  })
}

watch(status, ({ type }) => {
  if (timeout.value) {
    // Clear any existing timeout
    clearTimeout(timeout.value)

    timeout.value = undefined
  }

  if (type === 'error') {
    // Return back to idle after few seconds
    timeout.value = setTimeout(() => {
      status.value = { type: 'idle', text: props.text }

      timeout.value = undefined
    }, 5000)
  } else if (type === 'success') {
    if (props.repeat === 'captive') {
      // Reset immediately but keep current text
      status.value = { type: 'idle', text: status.value.text }
    } else if (props.repeat !== 'once') {
      // Reset after timeout if we want the upload to be repeatable
      timeout.value = setTimeout(() => {
        status.value = { type: 'idle', text: props.text }

        timeout.value = undefined
      }, 5000)
    }
  }
})

</script>
<style lang="scss" scoped>
i.loading {
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
}
</style>
